<script lang="ts">
    import RoundsNav from './RoundsNav.svelte';
    import BannedModal from './BannedModal.svelte';
    import SearchBox from '@/Components/SearchBox.svelte';
    import SuggestionsBox from '@/Components/SuggestionsBox.svelte';
    import * as Dialog from '$lib/components/ui/dialog';
    import * as DropdownMenu from '$lib/components/ui/dropdown-menu';
    import { Menu } from 'lucide-svelte';
    import axios from 'axios';
    import { onDestroy, onMount } from 'svelte';
    import { DateTime } from 'luxon';
    import { inertia } from '@inertiajs/svelte';
    export let suggestions;
    export let rounds;
    export let userRoundScore;
    export let baseScoreToBan;
    let hasTwitterConnected = true;
    export let theme;
    export let sidebarSpot;
    export let suggestionSlider;
    let currentRoute: string = '';
    let countDowns: any = {};
    let interval: any;
    let tweetURL: string;
    let followers_count = 3000;
    let csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';
    let showUrlModal = false;
    let showRulesModal = false;
    let showSuccessModal = false;
    let showErrorModal = false;
    let selectedRoundId = '';

    const getTime = () => {
        const now = DateTime.utc();

        rounds.forEach((round: any) => {
            const targetDate = DateTime.fromFormat(round.start_timestamp, 'yyyy-MM-dd HH:mm:ss', { zone: 'utc' });

            const diff: any = targetDate.diff(now, ['days', 'hours', 'minutes', 'seconds']).toObject();

            const distance = targetDate.diff(now).milliseconds;

            countDowns[round.id] =
                distance <= 0
                    ? {
                          staus: 'Expired',
                      }
                    : {
                          days: String(Math.max(0, Math.floor(diff.days))).padStart(2, '0'),
                          hours: String(Math.max(0, Math.floor(diff.hours))).padStart(2, '0'),
                          minutes: String(Math.max(0, Math.floor(diff.minutes))).padStart(2, '0'),
                          seconds: String(Math.max(0, Math.floor(diff.seconds))).padStart(2, '0'),
                      };
        });
    };

    // const followersCount = async () => {
    //     try {
    //         const response = await axios.get('/twitter/followers_count');

    //         if (response && response.status === 200) {
    //             console.log('Response', response);
    //         }
    //     } catch (error) {
    //         console.log('Error', error);
    //     }
    // };

    const joinRound = async () => {
        try {
            const response = await axios.post(
                '/rounds/join',
                { roundId: selectedRoundId, tweetUrl: tweetURL },
                {
                    headers: {
                        'X-CSRF-TOKEN': csrfToken,
                    },
                }
            );
            if (response && response.status === 200) {
                showRulesModal = false;
                showSuccessModal = true;
            }
        } catch (error) {
            showRulesModal = false;
            showErrorModal = true;
        }
    };

    onMount(() => {
        // followersCount();
        getTime();
        interval = setInterval(getTime, 1000);
        currentRoute = window.location.pathname;
    });

    onDestroy(() => {
        clearInterval(interval);
    });

    const abbreviateNumber = (value: number) => {
        if (value < 1000) return value.toString();
        const suffixes = ['', 'K', 'M', 'B', 'T'];
        const suffixNum = Math.floor(Math.log10(value) / 3);
        const shortValue = (value / Math.pow(1000, suffixNum)).toFixed(1);
        return parseFloat(shortValue) + suffixes[Math.min(suffixNum, suffixes.length - 1)];
    };
</script>

<div class="container">
    <div class="row">
        <div class="w-full p-0 md:w-3/5 lg:w-2/3">
            <div class="flex items-center justify-between px-3 pt-3">
                <div class="">
                    <h5 class="mb-2 font-bold">Rounds</h5>
                    <h6 class="mr-4 mt-2 flex text-muted">Repost eachother and grow together</h6>
                </div>
                <div>
                    <DropdownMenu.Root>
                        {#if hasTwitterConnected}
                            <DropdownMenu.Trigger>
                                <button
                                    type="button"
                                    class="m-0 block px-2 py-1 text-{theme} text-gray-800 hover:text-gray-600 md:hidden {userRoundScore <=
                                    baseScoreToBan
                                        ? 'cursor-not-allowed'
                                        : ''}"
                                    disabled="{userRoundScore <= baseScoreToBan}"
                                >
                                    <Menu />
                                </button>
                            </DropdownMenu.Trigger>
                        {/if}

                        <DropdownMenu.Content>
                            <DropdownMenu.Group>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds'}" use:inertia class="p-2">{'Overview'}</a>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds/my'}" use:inertia class="p-2">{'Rounds participating'}</a>
                                </DropdownMenu.Item>
                                <DropdownMenu.Item>
                                    <a href="{'/rounds/history'}" use:inertia class="p-2">{'Rounds history'}</a>
                                </DropdownMenu.Item>
                            </DropdownMenu.Group>
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>
            </div>
            <hr />

            {#if hasTwitterConnected}
                <div class="hidden md:block {userRoundScore <= baseScoreToBan ? 'pointer-events-none blur-sm' : ''}">
                    <RoundsNav {currentRoute} />
                </div>
            {/if}

            <div class="m-3 {userRoundScore <= baseScoreToBan ? 'pointer-events-none blur-md' : ''}">
                {#if rounds && rounds.length > 0}
                    <div class="grid gap-3 lg:grid-cols-2">
                        {#each rounds as round}
                            <div
                                class="card w-full place-content-center rounded-xl p-3 text-center shadow-lg {followers_count <
                                round.min_follower_count
                                    ? 'opacity-60'
                                    : ''}"
                            >
                                <div class="mb-2 grid w-full place-content-center text-center">
                                    <div
                                        class="mb-2 text-2xl font-extrabold
                                {followers_count < round.min_follower_count
                                            ? 'bg-gradient-to-r from-gray-400 via-gray-600 to-gray-800'
                                            : 'bg-gradient-to-r from-bmn-600 to-bmn-500'}
                                 inline-block bg-clip-text text-transparent"
                                    >
                                        <span class="">{abbreviateNumber(round.min_follower_count)}+</span> Follower round
                                    </div>
                                    <div
                                        id="start-time-{round.id}"
                                        class="mt-2 flex justify-center text-center text-xs text-muted"
                                    >
                                        {DateTime.fromFormat(round.start_timestamp, 'yyyy-MM-dd HH:mm:ss')
                                            .toUTC()
                                            .setLocale('en-GB')
                                            .toFormat('dd/MM/yyyy, HH:mm:ss')}

                                        <!-- <div class="mx-14 h-[16px] w-full animate-pulse rounded bg-slate-200"></div> -->
                                    </div>

                                    <div id="countdown-{round.id}" class="flex items-center justify-center gap-1">
                                        {#if countDowns[round.id]?.status}
                                            <div class="text-center text-4xl font-normal">
                                                {countDowns[round.id].status}
                                            </div>
                                        {:else}
                                            <div class="flex items-center justify-center gap-1">
                                                <div>
                                                    <div class="flex items-center text-4xl font-normal">
                                                        {countDowns[round.id]?.days}
                                                        <span class="ml-1 text-base">:</span>
                                                    </div>
                                                    <div class="text-xs">days</div>
                                                </div>

                                                <div>
                                                    <div class="flex items-center text-4xl font-normal">
                                                        {countDowns[round.id]?.hours}

                                                        <span class="ml-1 text-base">:</span>
                                                    </div>
                                                    <div class="text-xs">hrs</div>
                                                </div>

                                                <div>
                                                    <div class="flex items-center text-4xl font-normal">
                                                        {countDowns[round.id]?.minutes}

                                                        <span class="ml-1 text-base">:</span>
                                                    </div>

                                                    <div class="text-xs">min</div>
                                                </div>

                                                <div>
                                                    <div class="flex items-center text-4xl font-normal">
                                                        {countDowns[round.id]?.seconds}
                                                    </div>
                                                    <div class="text-xs">sec</div>
                                                </div>
                                            </div>
                                        {/if}
                                        <!-- <div class="mx-4 mt-2 h-[56px] w-full animate-pulse rounded bg-slate-200"></div> -->
                                    </div>
                                </div>
                                <div class="grid w-full content-center text-center">
                                    {#if followers_count === null}
                                        <div class="mb-2 text-xs font-light text-muted">
                                            Users signed up: {round.participants_number}/{round.max_participants_number}
                                        </div>
                                        <a
                                            class="mb-0 flex justify-center rounded-xl bg-bmn-500 py-2 text-white"
                                            href="auth/twitter/connect"
                                        >
                                            <span class="mr-2">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    x="0px"
                                                    y="0px"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        d="M 2.3671875 3 L 9.4628906 13.140625 L 2.7402344 21 L 5.3808594 21 L 10.644531 14.830078 L 14.960938 21 L 21.871094 21 L 14.449219 10.375 L 20.740234 3 L 18.140625 3 L 13.271484 8.6875 L 9.2988281 3 L 2.3671875 3 z M 6.2070312 5 L 8.2558594 5 L 18.033203 19 L 16.001953 19 L 6.2070312 5 z"
                                                    ></path>
                                                </svg>
                                            </span>

                                            Connect account
                                        </a>
                                    {:else if followers_count < round.min_follower_count}
                                        <div class="text-xs font-medium text-red-500">
                                            You do not have sufficient followers {abbreviateNumber(
                                                round.min_follower_count
                                            )} to participate in this round.
                                        </div>
                                        <button
                                            type="button"
                                            disabled
                                            class="flex items-center justify-center rounded-xl bg-gradient-to-r from-gray-400 via-gray-600 to-gray-800 p-2 px-4 text-sm text-white"
                                        >
                                            Inaccessible
                                            <span class="ml-2"><ion-icon name="lock-closed"></ion-icon></span>
                                        </button>
                                    {:else}
                                        <div class="mb-2 text-xs font-light text-muted">
                                            Users signed up: {round.participants_number}/{round.max_participants_number}
                                        </div>
                                        <button
                                            id="button-{round.id}"
                                            class="mb-0 rounded-xl bg-bmn-500 px-4 py-2 text-white"
                                            on:click="{() => {
                                                selectedRoundId = round.id;
                                                showUrlModal = true;
                                            }}"
                                        >
                                            <div>Join round</div>
                                        </button>
                                    {/if}
                                </div>
                            </div>
                        {/each}
                    </div>
                {:else}
                    <div class="py-4 text-center">
                        <h2 class="text-2xl font-extrabold">No Upcoming Rounds</h2>
                        <p>Currently, there are no rounds available. Please check back later!</p>
                    </div>
                {/if}
            </div>
        </div>

        <div
            class="suggestions-wrapper
                  hidden min-h-screen w-full border-l pb-5 pt-4 md:block md:w-2/5 lg:w-1/3"
        >
            <div class="feed-widgets">
                <div class="mb-4">
                    <SearchBox />
                </div>
                {#if !suggestionSlider}
                    <SuggestionsBox {suggestions} isMobile="{false}" />
                {/if}
                {#if sidebarSpot}
                    <div class="mt-4">
                        {sidebarSpot}
                    </div>
                {/if}
            </div>
        </div>
    </div>

    {#if userRoundScore <= baseScoreToBan}
        <BannedModal />
    {/if}

    <Dialog.Root bind:open="{showRulesModal}">
        <Dialog.Overlay class="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity" />

        <Dialog.Content class="max-h-[90vh] overflow-y-auto sm:max-w-[425px]">
            <Dialog.Header>
                <Dialog.Title>Round Rules</Dialog.Title>
            </Dialog.Header>

            <ul class="">
                <li class="p-1">
                    <span class="font-bold">This is what rounds are about:</span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                    mollit anim id est laborum.
                </li>
                <li class="p-1">
                    <span class="font-bold">Phase 1 explanation:</span>Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </li>
                <li class="p-1">
                    <span class="font-bold">Phase 2 explanation:</span>Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </li>
                <li class="p-1">
                    <span class="font-bold">What you should keep in mind:</span>Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </li>
                <li class="p-1">
                    <span class="font-bold">What you should do:</span>Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Excepteur sint occaecat
                    cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </li>
                <li class="p-1">
                    <span class="font-bold">What you should not do:</span>Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </li>
            </ul>

            <Dialog.Footer>
                <button
                    class="w-full rounded bg-bmn-500 py-2 text-white"
                    on:click="{() => {
                        joinRound();
                    }}"
                >
                    Agree to rules and continue to round
                </button>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog.Root>

    <Dialog.Root bind:open="{showUrlModal}">
        <Dialog.Overlay class="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity" />

        <Dialog.Content class="sm:max-w-[425px]">
            <div class="mb-0">
                <label for="tweet-url" class="mb-3 font-bold">
                    {'You must register with a tweet from your connected twitter account in order to participate in a round; please enter the URL to your tweet.'}
                </label>
                <input
                    type="url"
                    placeholder="https://example/status/1234567890.."
                    class="h-10 w-full rounded-sm border-2 border-gray-400 p-2 text-muted"
                    id="tweetURL"
                    bind:value="{tweetURL}"
                    autocomplete="off"
                />
            </div>
            <Dialog.Footer>
                <button
                    class="w-full rounded bg-bmn-500 py-2 text-white"
                    on:click="{() => {
                        showRulesModal = true;
                        showUrlModal = false;
                    }}"
                >
                    Submit URL
                </button>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog.Root>

    <Dialog.Root bind:open="{showSuccessModal}">
        <Dialog.Overlay class="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity" />

        <Dialog.Content class="sm:max-w-[425px]">
            <Dialog.Header>
                <Dialog.Title>Success</Dialog.Title>
            </Dialog.Header>

            <div>You have successfully joined the round!</div>
            <Dialog.Footer>
                <button
                    type="button"
                    class="cursor-pointer rounded bg-bmn-500 px-2 py-2 text-white"
                    on:click="{() => {
                        showSuccessModal = false;
                    }}">OK</button
                >
                <a class="" href="{'/rounds/my'}" use:inertia>Take me there</a>
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog.Root>

    <Dialog.Root bind:open="{showErrorModal}">
        <Dialog.Overlay class="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity" />

        <Dialog.Content class="sm:max-w-[425px]">
            <Dialog.Header>
                <Dialog.Title>Error</Dialog.Title>
            </Dialog.Header>

            <div>There was an error joining the round. Please try again.</div>
            <Dialog.Footer>
                <button
                    type="button"
                    class="cursor-pointer rounded bg-bmn-500 px-2 py-2 text-white"
                    on:click="{() => {
                        showErrorModal = false;
                    }}">OK</button
                >
            </Dialog.Footer>
        </Dialog.Content>
    </Dialog.Root>
</div>
